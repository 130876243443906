import Vue from "vue";
import VueRouter from "vue-router";
import BMMHome from "../views/Dashboard/Dashboard.vue";
import Users from "../views/Users/Users.vue";
import Insurers from "../views/Insurers/Insurers.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: BMMHome
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  },
  {
    path: "/insurers",
    name: "Insurers",
    component: Insurers
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
