var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "v-app-bar",
        { staticClass: "grey lighten-3", attrs: { flat: "", app: "" } },
        [
          _vm.getCurrentUser != null
            ? _c("v-app-bar-nav-icon", {
                staticClass: "grey--text",
                on: { click: _vm.toggleDrawer }
              })
            : _vm._e(),
          _c("v-toolbar-title", { staticClass: "text-uppercase grey--text" }, [
            _c("span", [
              _c("img", {
                staticClass: "w-auto h-8 mr-3 sm:h-10",
                attrs: {
                  src: require("../../../assets/images/logo-17.png"),
                  alt: "dex-logo"
                }
              })
            ])
          ]),
          _c("v-spacer"),
          _vm.getCurrentUser != null
            ? _c(
                "v-btn",
                {
                  attrs: { color: "secondary", outlined: "" },
                  on: { click: _vm.signOut }
                },
                [
                  _c("span", [_vm._v("Sign Out")]),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("exit_to_app")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "primary",
          attrs: { value: _vm.getDrawerStatus, app: "", dark: "" }
        },
        [
          _c(
            "v-list",
            { staticClass: "pt-16" },
            [
              _c(
                "v-list-item-group",
                _vm._l(_vm.links, function(link, i) {
                  return _c(
                    "v-list-item",
                    { key: i, attrs: { router: "", to: link.route } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            domProps: { textContent: _vm._s(link.icon) }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(link.text) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }