import Vue from "vue";
import Vuex from "vuex";
import usersModule from "../views/Users/Store";
import tenantModule from "../views/Insurers/Store";
import firebase from "firebase/app";
import { isAdmin, isViewer } from "../../../utils/roles.js"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    drawerStatus: true,
    currentBmmUser: null
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setDrawerStatus(state, payload) {
      state.drawerStatus = payload;
    },
    setcurrentBmmUser(state, payload) { state.currentBmmUser = payload; }
  },
  actions: {
    async authAction({ commit, dispatch, state }) {
      const user = firebase.auth().currentUser;
      if (user) {
        user.getIdToken(true).then(token => console.log(token));
        const tokenResult = await user.getIdTokenResult();
        commit("setcurrentBmmUser", { id: user.uid, role: tokenResult.claims });
        commit("setCurrentUser", user);
        commit("setDrawerStatus", true);
        dispatch("getTenants");
        dispatch("getUsers");
        if (state.tenantId != "admin") {
          // dispatch("getProducts");
          // dispatch("getDistributors");
        }
      } else {
        commit("setCurrentUser", null);
        commit("setDrawerStatus", false);
      }
    }
  },
  modules: { usersModule, tenantModule },
  getters: {
    getDrawerStatus: state => state.drawerStatus,
    isCurrentUserData: (state) => (user) => { return state.currentUser.email === user.email },
    isAdmin: state => { return isAdmin(state.currentBmmUser) },
    isViewer: state => { return isViewer(state.currentBmmUser) }
  }
});
