var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Add New User ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("User Details")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Name*",
                          rules: [_vm.rules.required, _vm.rules.display_name],
                          required: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Email*",
                          required: "",
                          outlined: "",
                          rules: [_vm.rules.required, _vm.rules.email]
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Password*",
                          type: "password",
                          rules: [_vm.rules.required, _vm.rules.password],
                          required: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            label: "Select User Role*",
                            value: "Viewer",
                            mandatory: "",
                            row: ""
                          },
                          model: {
                            value: _vm.role,
                            callback: function($$v) {
                              _vm.role = $$v
                            },
                            expression: "role"
                          }
                        },
                        _vm._l(_vm.roles, function(r, n) {
                          return _c("v-radio", {
                            key: n,
                            attrs: { label: r, value: r }
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex justify-end space-x-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "grey darken-1",
                                text: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeDialog($event)
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "blue darken-1",
                                outlined: "",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.createUser($event)
                                }
                              }
                            },
                            [_vm._v(" Add user ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }