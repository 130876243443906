var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c(
        "v-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { col: "12" } },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-4" },
                            [
                              _vm._v(" Customers "),
                              _c("v-spacer"),
                              !_vm.isViewer ? _c("InsurerDialogue") : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.getLoadingTenants,
                          "loading-text": "Fetching List of Customers",
                          headers: _vm.headers,
                          items: _vm.getTenants,
                          "items-per-page": 5,
                          flat: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }