var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                { staticClass: "mx-4" },
                [
                  _vm._v(" Users "),
                  _c("v-spacer"),
                  _vm.isAdmin ? _c("UserDialog") : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              loading: _vm.getLoadingUsers,
              "loading-text": "Fetching List of Users",
              headers: _vm.headers,
              items: _vm.getUsers,
              "items-per-page": 5,
              flat: ""
            },
            scopedSlots: _vm._u([
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    !_vm.isCurrentUserData(item) && _vm.isAdmin
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDialog(item)
                                  }
                                }
                              },
                              [_vm._v("mdi-pencil")]
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteUser(item)
                                  }
                                }
                              },
                              [_vm._v("mdi-delete")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.role",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.role != null
                      ? _c("span", [_vm._v(_vm._s(_vm.userRole(item)))])
                      : _c("span", [_vm._v("No role")])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Update User Role")
              ]),
              _c(
                "v-radio-group",
                {
                  staticClass: "ma-4",
                  model: {
                    value: _vm.role,
                    callback: function($$v) {
                      _vm.role = $$v
                    },
                    expression: "role"
                  }
                },
                _vm._l(_vm.roles, function(r, n) {
                  return _c("v-radio", {
                    key: n,
                    attrs: { label: r, value: r }
                  })
                }),
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red lighten-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.updateUserClaims }
                    },
                    [_vm._v("Update")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }