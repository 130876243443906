<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Add New User
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">User Details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              label="Name*"
              v-model="name"
              :rules="[rules.required, rules.display_name]"
              required
              outlined
            ></v-text-field>

            <v-text-field
              label="Email*"
              required
              outlined
              v-model="email"
              :rules="[rules.required, rules.email]"
            ></v-text-field>

            <v-text-field
              label="Password*"
              type="password"
              v-model="password"
              :rules="[rules.required, rules.password]"
              required
              outlined
            ></v-text-field>

            <v-radio-group v-model="role" label="Select User Role*" value="Viewer" mandatory row>
              <v-radio
                v-for="(r, n) in roles"
                :key="n"
                :label="r"
                :value="r"
              ></v-radio>
            </v-radio-group>

            <div class="flex justify-end space-x-3">
              <v-btn color="grey darken-1" text small @click.stop.prevent="closeDialog">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" outlined small @click.stop.prevent="createUser">
                Add user
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    roles: ["Viewer", "Admin", "Editor"],
    role: "",
    name: "",
    email: "",
    password: "",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      display_name: name => name.length >= 4 || "Name has to be 4 characters or more",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      password: pass => pass.length >= 6 || "Password has to be 6 characters or more",
    }
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    createUser() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        const data = {
          display_name: this.name,
          email: this.email,
          password: this.password,
          role: this.role
        };
        this.$store.dispatch("createUser", data);
        this.closeDialog();
      }
    }
  }
};
</script>

<style></style>
