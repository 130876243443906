<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"
          >Add New Customer</v-btn
        >
      </template>
      <v-card>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <section class="my-2">
              <h2 class="mb-4 text-lg">Company</h2>

              <v-text-field
                label="Company name*"
                outlined
                required
                :rules="[rules.display_name]"
                v-model="company_name"
              ></v-text-field>

              <v-select
                outlined
                v-model="tenant_type"
                :items="tenant_type_items"
                item-text="label"
                item-value="value"
                label="Customer type*"
                persistent-hint
                return-object
                single-line
              ></v-select>

              <v-text-field
                v-model="sub_domain"
                label="Domain Prefix*"
                suffix=".dxsuitebm.io"
                outlined
                required
              ></v-text-field>
            </section>

            <section class="my-2">
              <h2 class="mb-4 text-lg">Admin</h2>

              <v-text-field
                label="Name*"
                type="text"
                :rules="[rules.display_name]"
                v-model="admin_name"
                outlined
                required
              ></v-text-field>

              <v-text-field
                label="Email*"
                type="email"
                :rules="[rules.email]"
                v-model="email"
                outlined
                required
              ></v-text-field>

              <v-text-field
                label="Password*"
                type="password"
                :rules="[rules.password]"
                v-model="pass"
                outlined
                required
              ></v-text-field>
            </section>

            <div class="flex justify-end">
              <v-btn color="grey darken-1" text @click.stop.prevent="cancel">
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click.stop.prevent="createTenant"
              >
                Add customer
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    company_name: "",
    admin_name: "",
    sub_domain: "",
    tenant_type: { label: "Insurer", value: "Insurer" },
    tenant_type_items: [
      { label: "Insurer", value: "Insurer" },
      { label: "Distributor", value: "Distributor" },
    ],
    email: "",
    pass: "",
    rules: {
      display_name: (name) =>
        name.length >= 4 || "Name has to be 4 characters or more",
      name_pattern: (name) => {
        const pattern = /^[a-zA-Z]+$/;
        return pattern.test(name) || "Name can only contain letters";
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      password: (pass) =>
        pass.length >= 6 || "Password has to be 6 characters or more",
    },
  }),
  methods: {
    resetData() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    cancel() {
      this.dialog = false;
      this.resetData();
    },
    createTenant() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        const data = {
          company_name: this.company_name,
          admin_name: this.admin_name,
          subdomain: this.sub_domain,
          email: this.email,
          password: this.pass,
          tenant_type: this.tenant_type.value,
        };
        console.log(`Creating new tenant: ${JSON.stringify(data)}`);
        this.$store.dispatch("createTenant", data);
        this.dialog = false;
        this.resetData();
      }
    },
  },
};
</script>

<style></style>
