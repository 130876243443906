var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("Add New Customer")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c(
                        "section",
                        { staticClass: "my-2" },
                        [
                          _c("h2", { staticClass: "mb-4 text-lg" }, [
                            _vm._v("Company")
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: "Company name*",
                              outlined: "",
                              required: "",
                              rules: [_vm.rules.display_name]
                            },
                            model: {
                              value: _vm.company_name,
                              callback: function($$v) {
                                _vm.company_name = $$v
                              },
                              expression: "company_name"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              items: _vm.tenant_type_items,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Customer type*",
                              "persistent-hint": "",
                              "return-object": "",
                              "single-line": ""
                            },
                            model: {
                              value: _vm.tenant_type,
                              callback: function($$v) {
                                _vm.tenant_type = $$v
                              },
                              expression: "tenant_type"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Domain Prefix*",
                              suffix: ".dxsuitebm.io",
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.sub_domain,
                              callback: function($$v) {
                                _vm.sub_domain = $$v
                              },
                              expression: "sub_domain"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "section",
                        { staticClass: "my-2" },
                        [
                          _c("h2", { staticClass: "mb-4 text-lg" }, [
                            _vm._v("Admin")
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              label: "Name*",
                              type: "text",
                              rules: [_vm.rules.display_name],
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.admin_name,
                              callback: function($$v) {
                                _vm.admin_name = $$v
                              },
                              expression: "admin_name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Email*",
                              type: "email",
                              rules: [_vm.rules.email],
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Password*",
                              type: "password",
                              rules: [_vm.rules.password],
                              outlined: "",
                              required: ""
                            },
                            model: {
                              value: _vm.pass,
                              callback: function($$v) {
                                _vm.pass = $$v
                              },
                              expression: "pass"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "grey darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.cancel($event)
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.createTenant($event)
                                }
                              }
                            },
                            [_vm._v(" Add customer ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }