<template>
  <v-app>
    <Navbar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  name: "BMMApp",
  components: { Navbar },
  data: () => ({}),
  created() {
    this.$store.dispatch("authAction");
  },
  mounted() {
    // Moved to created () hook
    // this.$store.dispatch("authAction");
    
    // Takes care of a path duplication error
    // this.$router.replace({ name: "Home" });
    const path = "/"
    if (this.$route.path !== path) this.$router.push(path)
  }
};
</script>

<style></style>
