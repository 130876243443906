import axios from "axios";

export default {
  state: {
    users: [],
    loadingUsers: false
  },
  actions: {
    getUsers({ commit, rootState }) {
      commit("setLoadingUsers", true);
      const user = rootState.currentUser;
      const tenant_id = "admin";
      var url =
        `${process.env.VUE_APP_API_URL}/get_users`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          if (tenant_id != "admin") {
            url = url + "?tenant_id=" + tenant_id;
          }
          const response = await axios.get(url, {
            headers: headers
          });
          commit("setUsers", response.data);
        })
        .catch(error => {
          console.log(error);
          commit("setUsers", []);
        });
    },
    createUser({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/create_bmm_user`;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getUsers");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteUser({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/delete_user`;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getUsers");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    updateUserClaims({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        const url = `${process.env.VUE_APP_API_URL}/update_user_claims`;
        const user = rootState.currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, payload, {
              headers: headers
            });
            if (response.status == "200") {
              dispatch("getUsers");
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
      state.loadingUsers = false;
    },
    setLoadingUsers(state, payload) {
      state.loadingUsers = payload;
    }
  },
  getters: {
    getUsers: state => state.users,
    getLoadingUsers: state => state.loadingUsers
  }
};
