var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c(
        "v-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [_c("v-col", { attrs: { md: "12" } }, [_c("UserTable")], 1)],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }