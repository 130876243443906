import axios from "axios";
import firebase from "firebase/app";

export default {
  state: {
    tenants: [],
    loadingTenants: false
  },
  actions: {
    getTenants({ commit }) {
      commit("setLoadingTenants", true);
      const user = firebase.auth().currentUser;
      const url =
        `${process.env.VUE_APP_API_URL}/get_tenants`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          const response = await axios.get(url, {
            headers: headers
          });
          commit("setTenants", response.data);
        })
        .catch(error => {
          console.log(error);
          commit("setTenants", []);
        });
    },
    createTenant({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingTenants", true);
        const url = `${process.env.VUE_APP_API_URL}/create_tenant`;
        const user = rootState.currentUser;
        let data = payload;
        data.allow_password_sign_up = true;
        data.enable_email_link_sign_in = false;
        console.log("The data");
        console.log(data);
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.post(url, data, {
              headers: headers
            });
            if (response.status == "200") {
              console.log("Success");
              dispatch("getTenants");
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        console.log("User does not have permission to create tenant")
      }
    }
  },
  mutations: {
    setTenants(state, payload) {
      state.tenants = payload;
      state.loadingTenants = false;
    },
    setLoadingTenants(state, payload) {
      state.loadingTenants = payload;
    }
  },
  getters: {
    getInsurerDialogueStatus: state => state.insurerDialogue,
    getLoadingTenants: state => state.loadingTenants,
    getTenants: state => state.tenants
  }
};
