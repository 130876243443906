<template>
  <nav>
    <v-app-bar flat app class="grey lighten-3">
      <v-app-bar-nav-icon
        @click="toggleDrawer"
        class="grey--text"
        v-if="getCurrentUser != null"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <!-- <span class="font-weight-light">BMM</span>
        <span>DEX</span> -->
        <span>
          <img
            class="w-auto h-8 mr-3 sm:h-10"
            src="../../../assets/images/logo-17.png"
            alt="dex-logo"
          />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="secondary" outlined v-if="getCurrentUser != null" @click="signOut">
        <span>Sign Out</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer :value="getDrawerStatus" app dark class="primary">
      <v-list class="pt-16">
        <v-list-item-group>
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            router
            :to="link.route"
          >
            <v-list-item-icon>
              <v-icon v-text="link.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // getDrawerStatus: false,
      getCurrentUser: firebase.auth().currentUser,
      links: [
        {
          icon: "home",
          text: "Dashboard",
          route: "/"
        },
        {
          icon: "person",
          text: "Users",
          route: "/users"
        },
        {
          icon: "account_balance",
          text: "Customers",
          route: "/insurers",
          admin: true
        }
      ]
    };
  },
  methods: {
    async signOut() {
      try {
        this.$store.commit("setCurrentUser", null);
        const baseUrl = window.location.origin;
        window.location.assign(baseUrl);
        const data = await firebase.auth().signOut();
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    toggleDrawer() {
      this.$store.commit("setDrawerStatus", !this.$store.state.drawerStatus);
    }
  },
  computed: {
    ...mapGetters(["getDrawerStatus"])
    // ...mapGetters(["drawerStatus"])
  }
};
</script>
