<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <v-row class="mx-4">
          Users
          <v-spacer></v-spacer>
          <!-- <v-btn class="primary" @click="showDialogue">Add new Insurer</v-btn> -->
          <UserDialog v-if="isAdmin" />
        </v-row>
      </v-card-title>
      <v-data-table
        :loading="getLoadingUsers"
        loading-text="Fetching List of Users"
        :headers="headers"
        :items="getUsers"
        :items-per-page="5"
        flat
      >
        <template v-slot:item.actions="{ item }">
          <div v-if="!isCurrentUserData(item) && isAdmin">
            <v-icon small class="mr-2" @click="openDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteUser(item)">mdi-delete</v-icon>
          </div>
        </template>
        <template v-slot:item.role="{ item }">
          <span v-if="item.role != null">{{ userRole(item) }}</span>
          <span v-else>No role</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Update User Role</v-card-title>
        <v-radio-group v-model="role" class="ma-4">
          <v-radio
            v-for="(r, n) in roles"
            :key="n"
            :label="r"
            :value="r"
          ></v-radio>
        </v-radio-group>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red lighten-1" text @click="dialog = false"
            >Cancel</v-btn
          >

          <v-btn color="green darken-1" text @click="updateUserClaims"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserDialog from "./NewUserDialog.vue";
import { mapGetters } from "vuex";
import { bmm_roles, getUserRole } from "../../../../../utils/roles.js"
export default {
  components: {
    UserDialog
  },
  data() {
    return {
      dialog: false,
      editing_user: null,
      roles: bmm_roles,
      role: "Admin",
      headers: [
        {
          text: "UID",
          align: "start",
          sortable: true,
          value: "id"
        },
        { text: "Name", value: "display_name" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    openDialog(user) {
      this.dialog = true;
      this.editing_user = user;
      this.role = getUserRole(user);
    },
    showDialogue() {
      this.$store.commit("setUserDialog", true);
    },
    deleteUser(user) {
      var data = {
        email: user.email
      };
      this.$store.dispatch("deleteUser", data);
    },
    updateUserClaims() {
      var data = {
        uid: this.editing_user.id,
        role: this.role
      };
      console.log(this.editing_user.id);
      console.log(data);
      this.$store.dispatch("updateUserClaims", data);
      this.dialog = false;
    },
    userRole(user) {
      return getUserRole(user)
    }
  },
  computed: { ...mapGetters(["getUsers", "getLoadingUsers", "isCurrentUserData", "isAdmin"]) }
};
</script>

<style></style>
