<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <v-col col="12">
          <v-card outlined>
            <v-card-title>
              <v-row class="mx-4">
                Customers
                <v-spacer></v-spacer>
                <InsurerDialogue v-if="!isViewer" />
              </v-row>
            </v-card-title>
            <v-data-table
              :loading="getLoadingTenants"
              loading-text="Fetching List of Customers"
              :headers="headers"
              :items="getTenants"
              :items-per-page="5"
              flat
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import InsurerDialogue from "./Components/InsurerDialogue";
import { mapGetters } from "vuex";

export default {
  name: "Insurers",
  components: {
    InsurerDialogue
  },
  data() {
    return {
      headers: [
        {
          text: "Customer Name",
          align: "start",
          sortable: true,
          value: "display_name"
        },
        { text: "Id", value: "id" },
        { text: "Domain", value: "domain" },
        { text: "Active", value: "activity" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch("getTenants");
  },
  methods: {
    showDialogue() {
      this.$store.commit("setInsurerDialogue", true);
    }
  },
  computed: { ...mapGetters(["getTenants", "getLoadingTenants", "isViewer"]) }
};
</script>
