<template>
  <h1>BMM</h1>
</template>

<script>
export default {
};
</script>

<style></style>
